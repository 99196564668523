import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';
//import * as $ from 'jquery';
import { Sa800partnershipinfoService } from '../../modules/sa800main/sa800partnershipinfo.service';
import { DashboardServiceService } from '../../modules/dashboard/dashboard-service.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { FormGroup, FormControl, FormBuilder, Validators, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { ConfigurationService } from "../../../app/Configuration/configuration.service";
import { UsersService } from '../../modules/users/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { suppform } from '../../modules/sa800main/formselection/suppform';
import { ViewChildren } from '@angular/core';
import { QueryList } from '@angular/core';
import { ElementRef } from '@angular/core';
import { SideNavService } from '../sidenav/side-nav.service';
import { NgIf, NgFor, NgClass, SlicePipe, DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, NgClass, SlicePipe, DatePipe]
})
export class HeaderComponent implements OnInit {
  @ViewChildren("checkbox") checkboxes: QueryList<ElementRef>
  TaxpayerName_obj: any
  TaxpayerName: any;
  Sa800: Boolean;
  AdminLogin_time: boolean;
  UserLoginHistoryID: any;
  UserLoginID: any;
  time: FormGroup;
  UserID: any;
  UserCompanyName: any;
  NextTaxPeriod: any;
  PrevTaxPeriod: any;
  MasterUserID: any;
  response: any;
  UserType: any;
  TableRecordCnt: any = 0;
  ShowUpgrade: boolean = false;
  ShowRenew: boolean = false;
  Showupgrade: boolean = true;
  Successcunt: any = 0;
  MasterExpiryDate: any;
  UserMaster_Loginname: any;
  // Successcunt: any = 0;
  MasterLicenName: any;
  MasterUserName: any;
  // MasterExpiryDate: any;
  CurrTaxPeriod: any;
  limitAdd_taxper: any = 0;
  strEnddate: any;
  getlicen: any;
  MasterPurchasedDate: any;
  StrMasterExpiryDate: any;
  UsersList: any;
  Numberofusers: any;
  Number_usercreated: any;
  Licenceusers: any;
  response1: any = [];
  xmldata: any;
  xmlbase64: any;
  toggleSpinner: boolean = false;
  popup1: FormGroup;
  Finaltaxyear: string;
  ischecked: boolean;
  hide: boolean = false;
  chkboxchkd: boolean = false;
  alreadyimportedforms: any = [];
    Livesubmission: any;
  constructor(private formbulider: FormBuilder, private router: Router, private routing: ActivatedRoute, private oauthService: OAuthService, private dashboardService: DashboardServiceService, private spinner: NgxSpinnerService, public usersService: UsersService, public configurationService: ConfigurationService, private sa800Service: Sa800partnershipinfoService, private sa800dashboardService: DashboardServiceService, private sidenav: SideNavService, private toastr: ToastrService) {
    this.router.events.subscribe((event: Event) => {

    });
    this.time = new FormGroup({
      Last_Logout_bl: new FormControl(''),
      Last_Login_bl: new FormControl(''),
      User_ID: new FormControl(''),
      Flag: new FormControl(''),
      User_Login_History_ID: new FormControl(''),
      Years: new FormControl('')
    })

    this.popup1 = this.formbulider.group({
      inputFile: ['', []],
      Onlyforms: new FormControl(false),
      Alldata: new FormControl({value:false,disabled:true})
      //Alldata: new FormControl(false)
    })
  }

  ngOnInit() {
    debugger;
    
    this.ischecked = false;
    this.usersService.taxyeras();
    var Session_Object = JSON.parse(localStorage.getItem('id_token_claims_obj')); // for Azure
    this.usersService.Subscriptionyear = localStorage.getItem('styear');
    this.sa800Service.GetMasterUID(Session_Object.emails[0]).subscribe((data: any) => {  // for azure
      this.response = data;
      //this.response = this.response.filter(x => x.CreatedBy == null)
      //this.response = Array.from(this.response.reduce((m, t) => m.set(t.UserLoginID, t), new Map()).values());             //to avoid duplicates
      //if (this.response.length > 0) {
      //this.time.controls["Years"].setValue(this.response[0].TaxYear)
      //for showing taxyear in dropdown
      for (let i = 0; i < this.response?.length; i++) {
        var taxyears = this.response[i].TaxYear.split('-');
        var year2 = taxyears[1];
        if (taxyears[1].length > 2)
          year2 = taxyears[1].slice(2);
        var year = taxyears[0] + '-' + year2;
        this.response1[i] = [{ name: 'taxyear' }];
        this.response1[i].taxyear = this.response[i].TaxYear;
      }
      if (this.response.length > 0) {
        this.MasterUserID = this.response[0].MasterLoginUserID;
        localStorage.setItem('MasterUserID', this.MasterUserID)
        //localStorage.setItem('UserLoginID', this.response[0].UserLoginID)
        localStorage.setItem('MasterLicenName', this.response[0].MasterLicenName)
        localStorage.setItem('MasterUserName', this.response[0].MasterUserName)
        localStorage.setItem('MasterPurchasedDate', this.response[0].TUCreatedDate);
        localStorage.setItem('MasterExpiryDate', this.response[0].TUEndDate);


        if (!localStorage.getItem("Exists")) {
          this.usersService.Subscriptionyear = this.response[0].TaxYear;
          localStorage.setItem('styear', this.response[0].TaxYear);
          var taxyear1 = this.response[0].TaxYear.split('-')[0]
          var taxyear2 = this.response[0].TaxYear.split('-')[1]
          if (taxyear2.length < 4)
            taxyear2 = '20' + taxyear2
          this.Finaltaxyear = taxyear1 + '-' + taxyear2;
          this.usersService.Taxyear(this.Finaltaxyear);
        }
        this.usersService.taxyeras();
        var specifi_yeardata = this.response.filter(x => x.TaxYear == this.usersService.Subscriptionyear);
        var Utype = specifi_yeardata[0].UserType;
        this.strEnddate = specifi_yeardata[0].TUEndDate
        localStorage.setItem("UserType", Utype);
        this.Licencedetails(this.usersService.Current_taxyear)
      }
      else if (this.response?.length == 0) {
        this.toastr.toastrConfig.autoDismiss = true;
        this.toastr.toastrConfig.maxOpened = 1;
        this.toastr.toastrConfig.preventDuplicates = true;
        this.toastr.error('Invalid UserName or Password')
        this.oauthService.logOut();
        localStorage.clear();
      }
    })
    this.UserMaster_Loginname = Session_Object.emails[0];  // for Azure
    this.MasterLicenName = localStorage.getItem("MasterLicenName")
    this.MasterPurchasedDate = localStorage.getItem("MasterPurchasedDate")
    this.MasterExpiryDate = localStorage.getItem("MasterExpiryDate")
    this.CurrTaxPeriod = this.usersService.Currenttax_shortyear;
    if (localStorage.getItem('Number_Of_TaxPayers') != "null")      ////Number_Of_TaxPayers to Number_Of_Records
      this.limitAdd_taxper = localStorage.getItem('Number_Of_TaxPayers');
    if (this.limitAdd_taxper >= 99999) {
      this.limitAdd_taxper = "Unlimited";
      this.Showupgrade = false;
    }
    else {
      this.Showupgrade = true;
    }
    this.Successcunt = localStorage.getItem('Successcunt') ? localStorage.getItem('Successcunt') : 0;
    

    if (localStorage.getItem('TempStorage') == '1' && localStorage.getItem('NewEntry') == 'New') {

      this.TaxpayerName_obj = ''
    } else {

         this.TaxpayerName_obj = (localStorage.getItem("PartnershipName") ? CryptoJS.AES.decrypt(localStorage.getItem("PartnershipName"), "SA800").toString(CryptoJS.enc.Utf8) : "")
      //this.TaxpayerName_obj = this.usersService.partnershipname;
      //  this.TaxpayerName_obj = CryptoJS.AES.decrypt(localStorage.getItem("Taxpayername"), "SA800").toString(CryptoJS.enc.Utf8);
      //localStorage.getItem("Taxpayername");
      // console.log(this.TaxpayerName_obj);
      //this.sa800Service.Sa800.subscribe((value) => {
      //  //// 
      //  this.Sa800 = value;
      //  if (this.TaxpayerName_obj) {
      //    this.TaxpayerName = this.TaxpayerName_obj
      //  }
      //});

      this.UserID = (localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : "")
      if (!this.UserID) {
        $('#status').prop("disabled", true);
      }

      if (this.routing.snapshot.routeConfig.path != 'dashboard') {
        this.usersService.dropdown_method(false)
        this.usersService.dataimport = true;
      }


      this.usersService.dropdown_status.subscribe((data: any) => {
        if (data == true) {
          //this.TaxpayerName_obj = (localStorage.getItem("PartnershipName") ? CryptoJS.AES.decrypt(localStorage.getItem("PartnershipName"), "SA800").toString(CryptoJS.enc.Utf8) : "");
          this.time.controls["Years"].enable();
          this.time.controls["Years"].setValue(this.usersService.Subscriptionyear);
        }
        else
          this.time.controls["Years"].disable();
      })

      let x1 = this.usersService.yearchange.subscribe((data) => {
        if (data == true) {
          this.usersService.Year_method(false);
          this.ngOnInit();
          x1.unsubscribe();
        }
      })
    }
    this.usersService.data_import.subscribe((data) => {
      if (data == true) {
        this.popup1.reset();
        if (this.usersService.presentyearsupplyformdata)
          this.alreadyimportedforms = Object.fromEntries(Object.entries(this.usersService.presentyearsupplyformdata).filter(x => x[1] == true))
        this.alreadyimportedforms.SA800 = true
        $("#supplyimport").modal('show');
        $("#supplyimport").prop(false);
        this.TaxpayerName_obj = (localStorage.getItem("PartnershipName") ? CryptoJS.AES.decrypt(localStorage.getItem("PartnershipName"), "SA800").toString(CryptoJS.enc.Utf8) : "");
        this.usersService.Dataimport_Method(false);
      }
    })
    
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }
  public logout() {
    debugger;
    localStorage.setItem('TempShowStartOff', "0")
    this.UserLoginHistoryID = ((localStorage.getItem("UserLoginHistoryID")) ? Number(CryptoJS.AES.decrypt(localStorage.getItem("UserLoginHistoryID"), "SA800").toString(CryptoJS.enc.Utf8)) : null);
    this.UserLoginID = Number(localStorage.getItem('UserLoginID'));
    if (this.UserLoginHistoryID)
      this.time.controls["User_Login_History_ID"].setValue(this.UserLoginHistoryID);
    else
      this.time.controls["User_Login_History_ID"].setValue(null);
    this.time.controls["User_ID"].setValue(this.UserLoginID);
    this.time.controls["Flag"].setValue("U");
    this.time.controls["Last_Login_bl"].setValue(false);
    this.time.controls["Last_Logout_bl"].setValue(true);
    this.sa800dashboardService.insertTime(this.time.value).subscribe(data => {
      this.response = data;
      localStorage.setItem('MasterLogin_time', '');
      this.sa800Service.SetSelectedFormsValue(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false);
      // console.log(this.oauthService.getIdentityClaims())
      this.oauthService.logOut();
      localStorage.clear();

    });
  }
  //logout() {
  //  localStorage.clear()                                //local logout purpose
  //  this.router.navigate(['/users/login'])
  //}
  QuickStart() {
    this.router.navigate(['dashboard/quickstart'])
  }

  Aboutcancel() {
    $("#sa800License").modal('hide');
  }
  aboutPopup() {
    debugger
    this.dashboardService.getUserManagementDetails(localStorage.getItem('MasterUserID'), localStorage.getItem('UserLoginID'), this.usersService.Subscriptionyear).subscribe(resp => {
      this.UsersList = resp;
      this.Numberofusers = this.UsersList.userLogin;
      this.MasterLicenName = this.UsersList.userLogin[0].UserFullName;
      this.MasterUserName = this.UsersList.userLogin[0].UserName;
      this.Number_usercreated = this.Numberofusers.length;
      localStorage.setItem("Number_usercreated", this.Number_usercreated)
      localStorage.setItem("MasterLicenName", this.MasterLicenName)
      localStorage.setItem("MasterUserName", this.MasterUserName)


      this.TableRecordCnt = Number(localStorage.getItem("TableRecordCnt"));
      if (((localStorage.getItem('Number_Of_Records') == (this.TableRecordCnt)) || (localStorage.getItem('Number_Of_Users') == this.Number_usercreated)) && (this.UserType == 'Administrator')) {
        this.ShowUpgrade = true;
      } else {
        this.ShowUpgrade = false;
      }
      this.Livesubmissioncount()
    });
    var Session_Object = JSON.parse(localStorage.getItem('id_token_claims_obj')); // for Azure
    this.UserMaster_Loginname = Session_Object.emails[0];  // for Azure
    this.MasterLicenName = localStorage.getItem("MasterLicenName")
    this.MasterUserName = localStorage.getItem('MasterUserName')
    this.MasterPurchasedDate = localStorage.getItem("MasterPurchasedDate")
    this.MasterExpiryDate = localStorage.getItem("MasterExpiryDate")
    this.StrMasterExpiryDate = localStorage.getItem("StrMasterExpiryDate")
    this.CurrTaxPeriod = environment.CurrTaxPeriod;
    this.Licenceusers = localStorage.getItem('Number_Of_Users');
    if (localStorage.getItem('Number_Of_TaxPayers') != "null")      ////Number_Of_TaxPayers to Number_Of_Records
      this.limitAdd_taxper = localStorage.getItem('Number_Of_TaxPayers');
    if (this.limitAdd_taxper >= 99999) {
      this.limitAdd_taxper = "Unlimited";
      this.Showupgrade = false;
    }
    else {
      this.Showupgrade = true;
    }
    if (this.Licenceusers >= 99999) {
      this.Licenceusers = 'Unlimited'
      this.Showupgrade = false;
    }
    else {
      this.Showupgrade = true;
    }
    this.Successcunt = localStorage.getItem('Successcunt') ? localStorage.getItem('Successcunt') : 0;
    $("#sa800License").modal('show');
    this.UserType = localStorage.getItem('UserType');
    this.Successcunt = localStorage.getItem('Successcunt') ? localStorage.getItem('Successcunt') : 0;
    var date = new Date();
    var date2 = new Date(this.strEnddate)
    date2.setMonth(date2.getMonth() - 1);
    var dd = new Date(date2.toLocaleDateString());
    //let latest_date = this. datepipe.transform(date, "dd/MM/yyyy");
    if (date >= dd && this.UserType == 'Administrator') {   //this.MasterExpiryDate <=
      this.ShowRenew = true;
    }
    else {
      this.ShowRenew = false;
    }
  }
  HelpFile() {
    debugger;
  //  this.router.navigate(['help']);
    window.open("help?Quickstart", "", "width=800,height=1000");
  }
  Livesubmissioncount() {
    debugger
    this.dashboardService.getAllTaxpayer(localStorage.getItem('MasterUserID'), this.usersService.Current_taxyear).subscribe((resp: any) => {
      this.Livesubmission = resp.filter(x => x.LiveSubmission == true).length
    })
  }
  Upgrade(Type) {
    debugger
    //this.sa800Service.Getsubscriptioncheck(localStorage.getItem('MasterUserID')).subscribe(data => {
    //  var resp = eval('(' + data[0] + ')');
    //  var sa = resp[0].Isactive;
    this.sa800Service.GetUser_Licence_Details(localStorage.getItem('MasterUserID'), this.usersService.Subscriptionyear).subscribe(data => {
      this.getlicen = data;
      var sa = this.getlicen.IsActive;
      if (sa == true) {
        localStorage.setItem('Taxyear', this.getlicen.Version)
        var centralUserID = localStorage.getItem('MasterUserID');
        var subscriptionURL = this.configurationService.get().subscriptionURL;
        var encryptedData = CryptoJS.AES.encrypt(this.getlicen.ProductId.toString(), "My Secret Passphrase");
        var productId = encryptedData.toString();
        productId = productId.split('+').join('vkanslab');

        var encryptedData1 = CryptoJS.AES.encrypt(centralUserID, "My Secret Passphrase");
        var centralUserId = encryptedData1.toString();
        centralUserId = centralUserId.split('+').join('vkanslab');

        if (Type == 'Upgrade') {
          var encryptedData2 = CryptoJS.AES.encrypt("Upgrade", "My Secret Passphrase");
          var name = encryptedData2.toString();
          name = name.split('+').join('vkanslab');
        } else if (Type == 'Renew') {
          var encryptedData2 = CryptoJS.AES.encrypt("Renew", "My Secret Passphrase");
          var name = encryptedData2.toString();
          name = name.split('+').join('vkanslab');
        }
        var encryptedData3 = CryptoJS.AES.encrypt(localStorage.getItem('Number_Of_Records'), "My Secret Passphrase");   //Number_Of_TaxPayers to Number_Of_Users
        var numberOfTaxpayers = encryptedData3.toString();
        numberOfTaxpayers = numberOfTaxpayers.split('+').join('vkanslab');

        var encryptedData4 = CryptoJS.AES.encrypt("Annually", "My Secret Passphrase");
        var subType = encryptedData4.toString();
        subType = subType.split('+').join('vkanslab');

        //  this.TableRecordCnt = localStorage.getItem("TableRecordCnt");

        var encryptedData5 = CryptoJS.AES.encrypt(localStorage.getItem('Number_Of_Users'), "My Secret Passphrase");  //Number_Of_Users to Number_Of_Records
        var numberOfUsers = encryptedData5.toString();
        numberOfUsers = numberOfUsers.split('+').join('vkanslab');
        var encryptedData7 = CryptoJS.AES.encrypt(localStorage.getItem('STaxyear'), "My Secret Passphrase");  //localStorage.getItem("Taxyear")
        var TaxYear = encryptedData7.toString();
        TaxYear = TaxYear.split('+').join('vkanslab')
        var encryptedData8 = CryptoJS.AES.encrypt(localStorage.getItem("PlanId"), "My Secret Passphrase");
        var PlanId = encryptedData8.toString();
        PlanId = PlanId.split('+').join('vkanslab')
        var encryptedData9 = CryptoJS.AES.encrypt(localStorage.getItem("PricingId"), "My Secret Passphrase");
        var PricingId = encryptedData9.toString();
        PricingId = PricingId.split('+').join('vkanslab')

        if (Type == 'Upgrade') {
          var encryptedData6 = CryptoJS.AES.encrypt("FromTaxpayers", "My Secret Passphrase");
          var StatusFrom = encryptedData6.toString();
          StatusFrom = StatusFrom.split('+').join('vkanslab');
        } else if (Type == 'Renew') {
          debugger
          var encryptedData6 = CryptoJS.AES.encrypt("Renew", "My Secret Passphrase");
          var StatusFrom = encryptedData6.toString();
          StatusFrom = StatusFrom.split('+').join('vkanslab');
        }
        var URL = subscriptionURL + "/CustomerPlans?ProductName=" + productId + "&centralUserId=" + centralUserId + "&regType=" + name + "&numberOfCompanies=" + numberOfTaxpayers + "&subType=" + subType + "&RegUsers=" + numberOfUsers + "&StatusFrom=" + StatusFrom + "&TaxYear=" + TaxYear + "&PlanId=" + PlanId + "&PricingId=" + PricingId;
        window.open(URL, '_self');
      }
      else {
        Swal.fire('Andica SA800 Partnership', "This software licence is expired. Please contact Andica Sales.", "info");
        return;
      }
    })
  }
  getModule() {
    this.UserID = CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8)
    if (!this.UserID) {
      Swal.fire({
        title: 'Andica SA800 Partnership',
        icon: 'info',
        // title: 'Do you want to continue?',
        text: 'There is no partnership record opened. Open an existing partnership record or create a new record.',
        
        showCancelButton: false,
        confirmButtonText: 'Ok',
      }).then((result) => {
        if (result.value) {
          //window.location.href = "/dashboard";
        }
      })
    } else
      this.router.navigate(['dashboard/status'])
  }
  toggleSidebar() {
    $("#layoutSidenav_nav").toggle();
    $("#layoutSidenav_content").toggleClass("layout-show-hide");
  }
  changeYears(year) {
    debugger;
    //localStorage.clear()
    this.usersService.webimport_Method(false);
    this.usersService.Subscriptionyear = year;
    localStorage.setItem('styear', year);
    var year1 = year.split('-');
    if (year1[1].length < 4)
      year = year1[0] + '-20' + year1[1];
    this.sa800Service.setTaxpayerName("");
    localStorage.removeItem('PartnershipName')
    localStorage.removeItem('TaxPayerId')
    localStorage.removeItem('Amended_Return')
    localStorage.removeItem("SelectedUser");
    localStorage.removeItem("UserSelected");
    localStorage.removeItem('Amended_Return')
    localStorage.setItem('TempStorage', '1')
    this.sa800Service.SetSelectedFormsValue(false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false);
    this.usersService.Taxyear(year);
    this.usersService.taxyeras();
    this.time.controls['Years'].setValue(this.usersService.Subscriptionyear);
    localStorage.setItem("Exists", year);
    //this.route.navigate([], { relativeTo: this.routing, skipLocationChange: false })
   // this.spinner.show();
    this.usersService.Year_method(true);
    this.router.navigate(['/dashboard'])
    //this.Licencedetails(year)
    //this.localService.Year_method(true);
  }
  Licencedetails(year) {
    debugger;
    //this.usersService.Taxyear(this.Finaltaxyear);
    this.usersService.taxyeras();
    this.sa800Service.GetUser_Licence_Details(localStorage.getItem('MasterUserID'), this.usersService.Subscriptionyear).subscribe((data: any) => {
      this.getlicen = data;
      var sa = this.getlicen.IsActive;
      this.time.controls['Years'].setValue(this.usersService.Subscriptionyear);
      localStorage.setItem("Exists", year);
      if (sa == true) {
        localStorage.setItem('UserCompanyName', data.UserCompanyName);
        localStorage.setItem('Number_Of_TaxPayers', data.Number_Of_Records);
        localStorage.setItem('Number_Of_Users', data.Number_Of_Users);
        localStorage.setItem('Submissions', data.Submissions);
        localStorage.setItem('PlanId', data.PlanId)
        localStorage.setItem('PricingId', data.PricingId)
        localStorage.setItem('UserCompanyName', data.UserCompanyName);
        localStorage.setItem('ProductName', data.ProductId);
        localStorage.setItem('STaxyear', data.TaxYear);
        localStorage.setItem('MasterPurchasedDate', data.TUCreatedDate);
        localStorage.setItem('MasterExpiryDate', data.TUEndDate);
        localStorage.setItem('StrMasterExpiryDate', this.strEnddate);
        localStorage.setItem('TaxYear', this.usersService.Currenttax_shortyear);
        this.UserLoginID = this.response.filter(x => x.TaxYear == this.usersService.Subscriptionyear)[0].UserLoginID;
        localStorage.setItem('UserLoginID', this.UserLoginID)
        localStorage.setItem('Number_Of_TaxPayers', data.Number_Of_Records)
        if (localStorage.getItem('Number_Of_TaxPayers') != "null") {
          this.limitAdd_taxper = localStorage.getItem('Number_Of_TaxPayers');
          if (this.limitAdd_taxper >= 99999) {
            this.limitAdd_taxper = "Unlimited";
            this.Showupgrade = false;
          }
          else {
            this.Showupgrade = true;
          }
        }
        var Session_Object = JSON.parse(localStorage.getItem('id_token_claims_obj')); // for Azure
        this.UserMaster_Loginname = Session_Object.emails[0];  // for Azure
        this.UserCompanyName = localStorage.getItem("UserCompanyName");
        this.Number_usercreated = localStorage.getItem("Number_usercreated");
        this.MasterLicenName = localStorage.getItem("MasterLicenName")
        this.MasterPurchasedDate = localStorage.getItem("MasterPurchasedDate")
        this.MasterExpiryDate = localStorage.getItem("MasterExpiryDate")
        //this.NextTaxPeriod = environment.NextTaxPeriod;
        //this.PrevTaxPeriod = environment.PrevTaxPeriod;
        this.CurrTaxPeriod = this.usersService.Currenttax_shortyear;
        if (!localStorage.getItem("settaxyear"))
          localStorage.setItem('TaxYear', this.CurrTaxPeriod)
        if (localStorage.getItem('Number_Of_TaxPayers') != "null")
          this.limitAdd_taxper = localStorage.getItem('Number_Of_TaxPayers');
        if (this.limitAdd_taxper >= 99999) {
          this.limitAdd_taxper = "Unlimited";
          this.Showupgrade = false;
        }
        else {
          this.Showupgrade = true;
        }
        this.Successcunt = localStorage.getItem('Successcunt') ? localStorage.getItem('Successcunt') : 0;
        if (localStorage.getItem('TempStorage') == '1' && localStorage.getItem('NewEntry') == 'New') {
          this.TaxpayerName_obj = ''
        } else {
          this.TaxpayerName_obj = (localStorage.getItem("PartnershipName") ? CryptoJS.AES.decrypt(localStorage.getItem("PartnershipName"), "SA800").toString(CryptoJS.enc.Utf8) : "")
          this.sa800Service.Sa800.subscribe((value) => {
            this.Sa800 = value;
            if (this.TaxpayerName_obj) {
              this.TaxpayerName = this.TaxpayerName_obj;
              this.TaxpayerName_obj = (localStorage.getItem("PartnershipName") ? CryptoJS.AES.decrypt(localStorage.getItem("PartnershipName"), "SA800").toString(CryptoJS.enc.Utf8) : "");
            }
          });
        }
        // this.ngOnInit();
        //this.loadAllTaxPayer();
        //} else {
        //  Swal.fire('', "This software license is deactivated. Please contact Andica Sales.");
        //  return;
      }
    });
    //var specifi_yeardata = this.response.filter(x => x.TaxYear == this.usersService.Current_taxyear);
    //var Utype = specifi_yeardata[0].UserType;
    //this.strEnddate = specifi_yeardata[0].TUEndDate
    //localStorage.setItem("UserType", Utype);
  }
  //close() {
  //  this.usersService.Dataimport_Method(false)
  //  $("#Importdatamodal").modal('hide');
  //}
  handleFileInput(files) {
    this.xmldata = files;
    this.xmldata = files.target.files[0];
    if (files != null) {
      var reader = new FileReader();
      reader.readAsDataURL(this.xmldata);
      reader.onload = () => {
        debugger
        var data = reader.result;
        this.xmlbase64 = data;
      };
    }
  }
  Onlyforms(event) {
    debugger;
    if (event.target.checked) {
      this.popup1.controls['Alldata'].enable()
    }
    else {
      this.popup1.controls['Alldata'].setValue(false)
      this.popup1.controls['Alldata'].disable()
    }
  }
  Fileupload() {
    debugger;
    //if (this.chkboxchkd == false) {
    //  Swal.fire("Andica SA800 Partnership", "Select the form(s) to import.",'info');
    //  return false;
    //}
    //}
    if (this.usersService.supplyformdata.filter(x => x.checked == true).length > 0) {
      var id = localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : null;
      if (id) {
        this.sa800Service.get_Supp_data_Id(id).subscribe((data: any) => {
          if (data.SupplementaryFormID) {
            Swal.fire({
              title: 'Andica SA800 Partnership',
              icon: 'question',
              text: "Do you want to update existing data?",
              confirmButtonText: "Yes",
              showCancelButton: true
            }).then((result) => {
              if (result.value) {
                this.validationpopup();
              }
            })
          }
          else {
            this.validationpopup();
          }
        })
      }
    }
      else {
      Swal.fire("Andica SA800 Partnership", "Select the form(s) to import.", 'info');
      }
  }
  validatepopup() {
    debugger;
    if (this.usersService.windowsimportbtn) {
      if (this.popup1.controls['inputFile'].value == null || this.popup1.controls['inputFile'].value == "") {
        Swal.fire("Andica SA800 Partnership", "Please select xml file.", "info")
        return false;
      }
      if (this.popup1.controls['Onlyforms'].value != true) {
        Swal.fire("Andica SA800 Partnership", "Please check the checkboxes.", "info")
        return false;
      }
      if (this.usersService.dataimportpathName && this.xmldata.name != this.usersService.dataimportpathName) {
        Swal.fire("Andica SA800 Partnership", "The data file you have selected is not last year's file.", "info")
        return false;
      }
      if (this.xmldata.name.split('_')[0] != this.usersService.dataimportName) {
        Swal.fire("Andica SA800 Partnership", "Please select valid xml file.", "info")
        return false;
      }
      if (this.xmldata.type != "text/xml") {
        Swal.fire("Andica SA800 Partnership", "Please select xml file.", "info")
        return false;
      }
      if (!this.usersService.Importrecid) {
        Swal.fire("Andica SA800 Partnership", "There is no taxpayer exist with this name.", "info")
        return false;
      }
      return true;
    }
    else if (this.usersService.webimportbtn) {
      if (this.popup1.controls['Onlyforms'].value == null || this.popup1.controls['Alldata'].getRawValue() == null) {
        Swal.fire("Andica SA800 Partnership", "Please select checkbox(s).", "info")
        return false;
      }
      //if (this.popup1.controls['Alldata'].value == null || this.popup1.controls['Alldata'].value == "") {
      //  Swal.fire("", "Please select checkbox(s).")
      //  return false;
      //}
      return true;
    }
  }
  

  faIcontoggle: boolean = false;
  faIcon: string = 'fa fa-plus';
  showArea() {
    if (this.faIcontoggle == false) { this.faIcontoggle = true; this.faIcon = 'fa fa-minus'; }
    else { this.faIcontoggle = false; this.faIcon = 'fa fa-plus'; }
  }
  menudeselect: boolean = false;
  selectbutton() {
    this.menudeselect = !this.menudeselect
  }
  Upload(Flag) {
    debugger;
    //if (this.validatepopup()) {
    //if (this.usersService.windowsimportbtn) {
    //  var data = this.popup1.getRawValue();
    //  this.xmlbase64 = this.xmlbase64.split(",")
    //  this.xmlbase64 = this.xmlbase64[1]
    //  data.xmlbase64 = this.xmlbase64;
    //  data.TaxpayerId = this.usersService.Importrecid;
    //  data.TaxYear = this.usersService.PreviousFulltaxyear;
    //  if (data.Alldata == null)
    //    data.Alldata = true;
    //  this.toggleSpinner = true;
    //  this.sa800Service.dataimportwithxml(data).subscribe((data1) => {
    //    data.TaxpayerId = this.UserID;
    //    data.TaxYear = this.usersService.Current_taxyear;
    //    if (!this.popup1.controls['Alldata'].value)
    //      data.Alldata = false;
    //    this.sa800Service.dataimportwithxml(data).subscribe((data) => {
    //      $("#Importdatamodal").modal('hide');
    //      this.usersService.Dataimport_Method(false);
    //      this.toggleSpinner = false;
    //    })

    //  })
    //}
    //else if (this.usersService.webimportbtn) {
    //  var data = this.popup1.getRawValue();
    //  if (data.Onlyforms == true && data.Alldata)
    //    data.Flag = "Alldata";
    //  else if (data.Onlyforms == true && !data.Alldata)
    //   data.Flag = "Onlysupfroms";
    //  data.RecordID = localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : this.usersService.formdataid;
    //  data.SubFlag = "WebtowebFormdata";
    //  var arraydata = [];
    //  arraydata.push(data);
    //  this.dashboardService.importdataweb(arraydata).subscribe((result) => {
    //   //if (result) {
    //      Swal.fire("", "Partnership records(s) imported successfully.", "success")
    //      $("#Importdatamodal").modal('hide');
    //  // }
    //  })
    //}
    if (this.usersService.webimportbtn || this.usersService.windowsimportbtn) {
      var data = this.popup1.getRawValue();
      if (data.Onlyforms == true && data.Alldata)
        data.Flag = "Alldata";
      else if (data.Onlyforms == true && !data.Alldata)
        data.Flag = "Onlysupfroms";
      else
        data.FormsFlag = "Importsupplyforms"
      data.UserID = localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : this.usersService.formdataid;
      data.SubFlag = "WebtowebFormdata";
      var formdata = this.usersService.supplyformdata.filter(x => x.checked == true)
      var sp = new suppform();
      for (let i = 0; i < formdata.length; i++) {
        if (formdata[i].name == "UK Property") {
          formdata[i].name = "LandProperty"
          sp[formdata[i].name] = formdata[i].checked;
        }
        else if (formdata[i].name == "Trading and Professional") {
          formdata[i].name = "Trade"
          sp[formdata[i].name] = formdata[i].checked;
        }
        else if (formdata[i].name == "Disposal and Chargeable Assests") {
          formdata[i].name = "ChargeableAssets"
          sp[formdata[i].name] = formdata[i].checked;
        }
        else if (formdata[i].name == "Savings and Investments") {
          formdata[i].name = "IncomeNotIncludedElsewhere"
          sp[formdata[i].name] = formdata[i].checked;
        }
        else {
          sp[formdata[i].name] = formdata[i].checked;
        }
      }
      
      var arraydata = [];
      arraydata.push(data);
      if (data.FormsFlag = "Importsupplyforms") {
        sp.FormsFlag = "Importsupplyforms";
        sp.RecordID = localStorage.getItem("RecordID") ? CryptoJS.AES.decrypt(localStorage.getItem("RecordID"), "SA800").toString(CryptoJS.enc.Utf8) : this.usersService.formdataid
        sp.SubFlag = "WebtowebFormdata"
        sp.Flag = Flag;
        if (this.usersService.presentyearsupplyformdata)
        Object.assign(sp,Object.fromEntries(Object.entries(this.usersService.presentyearsupplyformdata).filter(x => x[1] == true)))
        this.dashboardService.importformsdata(sp).subscribe((data) => {
          if (data) {
            Swal.fire("Andica SA800 Partnership", "Supplementary form(s) successfully imported.", "success")
            $("#supplyimport").modal('hide');
            $("#successpopup").modal('hide');
          }
        })
      }
      else {
        this.dashboardService.importdataweb(arraydata).subscribe((result) => {
          if (result) {
            Swal.fire("Andica SA800 Partnership", "Partnership record(s) imported successfully.", "success")
            $("#supplyimport").modal('hide');
          }
        })
      }

    }
    //}
  }
  close() {
    if (this.usersService.issupplyid) {
      Swal.fire({
        title: 'Andica SA800 Partnership',
        icon: 'question',
        text: "Do you want to skip this import routine and continue to open partnership record?",
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "No"
      }).then((result) => {
        if (result.value) {
          $("#supplyimport").modal('hide');
          this.usersService.validationmessage(true);
        }
      })
    }
    else {
      this.usersService.Dataimport_Method(false)
      $("#supplyimport").modal('hide');

    }
  }
  skip() {
    this.usersService.Dataimport_Method(false)
    $("#supplyimport").modal('hide');
    this.usersService.validationmessage(true);
  }
  cancel() {
    $("#successpopup").modal('hide');
  }
  checkboxforsuppforms($event) {
    debugger;
    if ($event.target.checked == true) {
      this.ischecked = true;
    }
    else {
      this.ischecked = false;
    }
  }
  validationpopup() {
    $("#successpopup").modal('show');
  }
  importcheck($event, i) {
    debugger;
      this.usersService.supplyformdata[i].checked = $event.target.checked
  }
  selectAll(type) {
    debugger
    this.menudeselect = false
    if (type == 'select') {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = true;
        for (let i = 0; i < this.usersService.supplyformdata.length; i++) {
          this.usersService.supplyformdata[i].checked = true;
        }
      })
    }
    else if (type == 'deselect') {
      this.checkboxes.forEach((element) => {
        element.nativeElement.checked = false;
        for (let i = 0; i < this.usersService.supplyformdata.length; i++) {
          this.usersService.supplyformdata[i].checked = false;
        }
      })
    }
    else if (type == 'inverse') {
      this.checkboxes.forEach((element, i) => {
        if (this.usersService.supplyformdata[i].checked == false || this.usersService.supplyformdata[i].checked == null) {
          element.nativeElement.checked = true;
          this.usersService.supplyformdata[i].checked = true
        }
        else if (this.usersService.supplyformdata[i].checked == true) {
          element.nativeElement.checked = false;
          this.usersService.supplyformdata[i].checked = false
        }
      })
    }
  }
}
